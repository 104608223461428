/**
 * Because older browsers will interpret any object in the 3rd argument
 * as a true value for the capture argument, it's important for developers
 * to use feature detection or a polyfill when using this API, to avoid
 * unforeseen results.
 * Resource: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
 */
var supportsCaptureOption = false;
try {
    var opts = Object.defineProperty({}, 'capture', {
        get: function () {
            supportsCaptureOption = true;
        },
    });
    window.addEventListener('test', null, opts);
    window.removeEventListener('test', null, opts);
    // eslint-disable-next-line no-empty
}
catch (_a) { }
export var getSafeEventHandlerOpts = function (options) {
    if (options === void 0) { options = { capture: true }; }
    return supportsCaptureOption ? options : options.capture;
};
